<template>
    <Wrapper>
        <div :class="'general-container ' + brandName">
            <div class="elements">
                <h1>{{ $t('global.apps') }}</h1>
                <Element v-for="item in brandConfig.appsFiles" :key="item.id" :item="item" />
            </div>
            <div class="elements">
                <h1>{{ $t('global.guide') }}</h1>
                <Element v-for="item in brandConfig.appGuids" :key="item.id" :item="item" />
            </div>
        </div>
    </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        Element: () => import('./Element.vue')
    },
    computed: {
        ...mapGetters(['brandName', 'brandConfig'])
    }
};
</script>

<style scoped>
.general-container {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-bottom: 50px;
}

.PremiumClub h1 {
    color: black;
}

.apollogroup h1,
.pizzatime h1 {

    color: white;
}

.elements {
    width: 40%;
}

@media screen and (max-width: 1600px) {
    .elements {
        width: 45%;
    }
}

@media screen and (max-width: 1280px) {
    .general-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .elements {
        width: 70%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 600px) {
    .elements {
        width: 95%;
        margin-bottom: 20px;
    }
}
</style>
